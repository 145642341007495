<i18n>
{
  "en": {
  },
  "ja": {
  }
}
</i18n>

<template>
<div>
  <div class="list list--default">
    <div class="flex-grid">
      <!-- movie item -->
      <movie-thumbnail
        v-for="movie in movieList"
        :key="movie.MovieID"
        :movie="movie"
        :category-name="showCategory"
        theme="home-category" />
      <!-- /movie item -->
    </div>
  </div>

</div>
</template>

<script>
/* eslint max-len: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import movieThumbnail from '@/components/_common/movieThumbnail.vue';

export default {
  props: {
    numMovies: Number,
    showCategory: {
      type: String,
      required: false,
    },
  },
  components: {
    'movie-thumbnail': movieThumbnail,
  },
  data() {
    return {
      movieList: [],
    };
  },
  created() {
    this.getMovies(this.userTypeID);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  methods: {
    getMovies(userTypeID) {
      const bfAPI = new BifrostAPI();
      bfAPI.getCategories().then((categoryMap) => {
        Object.keys(categoryMap).forEach((categoryId) => {
          if (this.showCategory === categoryMap[categoryId].name.ja) {
            // backend can take an array of category IDs here, so forcing a single element array
            bfAPI.getCategoryList(0, this.numMovies, [parseInt(categoryId, 10)]).then((result) => {
              const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
              this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);
            });
          }
        });
      });
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovies(newVal);
    },
    numMovies(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovies(this.userTypeID);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
