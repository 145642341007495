<i18n>
{
  "en": {
    "recommended_categories": "Recommended Categories",
    "category_list": "Category List"
  },
  "ja": {
    "recommended_categories": "おすすめカテゴリー",
    "category_list": "カテゴリー一覧"
  }
}
</i18n>

<template>
<div>
  <div class="heading">
    <h2>{{ $t('recommended_categories') }}</h2>
  </div>

  <vue-tabs type="pills" id="top--tab-category">
    <v-tab v-for="category in categoryList" :key="category.id" :title="category.name[locale]">
      <div class="tab-item">
        <category-movies :num-movies="moviesPerTab" :show-category="category.name.ja" />
      </div>
    </v-tab>
  </vue-tabs>

  <div class="button-box button-box--center">
    <router-link to="/category/"><button class="button-ghost button-default button-large button-icon--right">{{ $t('category_list') }}<svg class="tm-icon"><use xlink:href="#tm-icon-arrow-right"/></svg></button></router-link>
  </div>

</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-unneeded-ternary: 0 */

import { VueTabs, VTab } from 'vue-nav-tabs';
import CategoryMovies from './categoryMovies.vue';
import CustomLists from '@/assets/js/services/CustomLists';

export default {
  props: {
    customListId: String,
    moviesPerTab: {
      type: Number,
      default: 8,
    },
  },
  components: {
    VueTabs,
    VTab,
    'category-movies': CategoryMovies,
  },
  data() {
    return {
      categoryList: [],
      categoryMap: {},
    };
  },
  async mounted() {
    const customListSvc = new CustomLists();
    this.categoryList = await customListSvc.getList('categories', this.customListId);

    // hide section header in the home page if there are no categories set
    this.$emit('categoriesEval', (this.categoryList) ? true : false);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
